import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { FormatLongDateValueConverter } from 'resources/value-converters/format-long-date';
import { Client, ClientCertificate, ClientHealthAndRepositories, ClientHttpsCertificate, ClientModule, ClientState, Health, ModuleType, MyHttpApi, PosTaskType, Repository } from 'utils/api';
import { HealthStatusResponse, HealthUtil } from 'utils/health-util';
import { Notify } from 'utils/notify';

export interface MonitoredTaskStatus {
  type: PosTaskType;
  eventTime?: Date;
  issues?: string;
}

@autoinject
export class AdminClientsShowStatus {
  private readonly formatLongDate = new FormatLongDateValueConverter;

  private clientId = 0;
  private client?: Client;
  private health?: Health;
  private currentRepository?: Repository;
  private targetRepository?: Repository;
  private nextMinorRepository?: Repository;
  private canUpgradeClient = false;
  private clientHealthAndRepositories?: ClientHealthAndRepositories;
  private updateWillHappen?: Date;
  private openVpnCert?: ClientCertificate;
  private httpsCert?: ClientHttpsCertificate;
  private status: HealthStatusResponse = { message: "" };
  private showStatusModal = false;

  private refreshTimeout?: NodeJS.Timeout;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private readonly posTaskTypes: PosTaskType[] = Object.keys({ ...this.i18n.i18next.getDataByLanguage(this.i18n.getLocale()).translation["PosTaskType"] });
  private monitoredTasks: MonitoredTaskStatus[] = [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  private readonly moduleTypes: ModuleType[] = Object.keys(this.i18n.i18next.getDataByLanguage(this.i18n.getLocale()).translation["ModuleType"]);
  private readonly moduleList: ClientModule[] = [];

  private stateHistoryList: ClientState[] = [];
  private repositories: { [key: number]: { name: string; }; } = {};

  private readonly clientStateFieldList: FieldSpec[] = [
    { key: "state", header: "client.state", type: "text", },
    { key: "stateTime", header: "common.timestamp", type: "date", },
    { key: "repositoryId", header: "client.version", type: "lookup", lookupData: this.repositories, lookupKey: "name" },
  ];

  constructor(private readonly notify: Notify, private readonly router: Router, private readonly api: MyHttpApi, private readonly i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    this.notify.unitId = this.clientId = parseInt(params.id);
    let [repositoryList] = await Promise.all([
      this.api.repositoryList({ onlyStable: false }),
      this.refresh(),
    ]);
    for (let repo of repositoryList) {
      this.repositories[repo.id] = {
        name: repo.branch + "-"
          + repo.abbreviatedSha + " "
          + this.formatLongDate.toView(repo.createTime)
          + " " + repo.name
      };
    }
  }

  deactivate(): void {
    this.cancelTimer();
  }

  cancelTimer() {
    if (this.refreshTimeout) {
      clearInterval(this.refreshTimeout);
      this.refreshTimeout = undefined;
    }
  }

  async refresh() {
    this.cancelTimer();

    let [chrAndCertificates, stateHistoryList] = await Promise.all([
      this.api.clientHealthAndRepositoriesWithCertificates({ id: this.clientId }),
      this.api.clientStateHistory({ id: this.clientId }),
    ]);
    this.clientHealthAndRepositories = chrAndCertificates.chr;
    this.openVpnCert = chrAndCertificates.openvpnCertificate;
    this.httpsCert = chrAndCertificates.httpsCertificate;
    this.stateHistoryList = stateHistoryList;
    this.canUpgradeClient = this.clientHealthAndRepositories.canUpgradeClient;
    this.health = this.clientHealthAndRepositories.health;
    if (this.clientHealthAndRepositories) {
      this.status = HealthUtil.generateStatusAndWarnings(this.i18n, { ...this.clientHealthAndRepositories });
    }
    this.currentRepository = this.clientHealthAndRepositories.currentRepository;
    this.targetRepository = this.clientHealthAndRepositories.targetRepository;
    this.nextMinorRepository = this.clientHealthAndRepositories.nextMinorRepository;
    this.updateWillHappen = this.clientHealthAndRepositories.nextUpdate;
    this.client = this.clientHealthAndRepositories.client;
    this.computeMonitoredTasks();

    this.refreshTimeout = setInterval(() => this.refresh(), 30000);
  }

  showStatusMessage() {
    this.showStatusModal = !!this.status.message;
  }

  computeMonitoredTasks() {
    let monitoredTasks = this.clientHealthAndRepositories?.tasks || [];
    this.monitoredTasks = this.posTaskTypes.map(type => (
      monitoredTasks.find(mt => mt.type === type) || {
        type,
        eventTime: undefined,
        issues: undefined
      }
    ));
  }

  @computedFrom("moduleTypes", "moduleList")
  get availableModules() {
    return this.moduleTypes
      .filter(mt => !this.moduleList.map(ml => ml.moduleType).includes(mt))
      .map(mt => {
        return {
          id: mt,
          name: this.i18n.tr("ModuleType." + mt),
        };
      });
  }

  @computedFrom("health.warningReasons")
  get warningReason() {
    return this.health?.warningReasons?.map(x => this.i18n.tr("WarningReason." + x)).join(", ");
  }

}
